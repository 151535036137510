<template>
  <div>
    <div class="header">
      <div class="header_top">
        <img src="../../assets/left.png" alt @click="goBack" />
        <span class="center">服务包管理</span>
        <img src="../../assets/right.png" alt />
        <span class="center">服务包列表</span>
        <img src="../../assets/right.png" alt />
        <span class="center">{{ title }}</span>
      </div>
      <div>
        <el-button @click="godel">清空</el-button>
      </div>
    </div>
    <el-card class="box-card">
      <div style="margin-bottom: 20px">
        <Divider :title="'基本信息'"></Divider>
        <el-form
          label-position="top"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="ruleForm"
          label-width="110px"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="服务包名称" prop="name">
                <el-input v-model="ruleForm.name" placeholder="请输入服务包名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="上游企业" prop="upperCode">
                <el-select
                  style="width: 90%"
                  filterable
                  allow-create
                  default-first-option
                  v-model="ruleForm.upperCode"
                  placeholder="请选择上游企业"
                >
                  <el-option
                    v-for="item in upperList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="服务包备注" prop="remark">
                <el-input type="textarea" v-model="ruleForm.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <Divider :title="'发薪企业信息'"></Divider> -->

          <Divider :title="'发薪企业信息'" :tag="`(最多四个企业)`">
            <span slot="right" style="color: #1989fa; font-size: 12px" @click="addE">添加发薪企业</span>
          </Divider>
          <el-row :gutter="24">
            <div style="margin:10px;font-size:14px">
              <span style="color: red;">*</span>单工伤公司
            </div>
            <el-col :span="12">
              <div class="hide_one">
                <el-select
                  style="width: 90%"
                  filterable
                  allow-create
                  default-first-option
                  v-model="complianceObj.ecode"
                  placeholder="请选择单工伤公司"
                  @change="addfieldEone(complianceObj.ecode)"
                >
                  <el-option
                    v-for="item in complianceList"
                    :key="item.code"
                    :disabled="item.dispatch"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="hide_one">
                <el-select
                  style="width: 90%"
                  v-model="complianceObj.pcode"
                  placeholder="请选择归属项目"
                  @change="changePone"
                >
                  <el-option
                    v-for="item in complianceProject"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="24">
            <div style="margin:10px;font-size:14px">
              <span style="color: red;">*</span>服务公司
            </div>
            <div v-for="(it, index) in spoceList" :key="index">
              <el-col :span="12" style="margin-bottom: 10px">
                <div class="hide_one">
                  <el-select
                    style="width: 90%"
                    filterable
                    allow-create
                    default-first-option
                    v-model="it.e"
                    placeholder="请选择服务公司"
                    @change="addfieldE(it)"
                  >
                    <el-option
                      v-for="item in enterpriseList"
                      :key="item.ecode"
                      :disabled="item.dispatch"
                      :label="item.ename"
                      :value="item.ecode"
                    ></el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="12" style="margin-bottom: 10px">
                <div class="hide_one">
                  <el-select
                    style="width: 90%"
                    v-model="it.pcode"
                    placeholder="请选择归属项目"
                    @change="changeP"
                    @focus="focusPro(it.e)"
                  >
                    <el-option
                      v-for="item in projectList"
                      :key="item.code"
                      :label="item.name"
                      :value="item.code"
                    ></el-option>
                  </el-select>
                  <el-popconfirm title="确定删除这条发薪企业吗？">
                    <el-button
                      v-if="spoceList.length > 1"
                      slot="reference"
                      type="text"
                      style="margin-left: 15px; color: #d9001b"
                      @click="delScope(it, index)"
                    >删除</el-button>
                  </el-popconfirm>
                </div>
              </el-col>
            </div>
          </el-row>
          <Divider :title="'工资信息'">
            <div slot="right" v-if="!templateShow">
              <el-upload
                style="text-align: left"
                class="upload-demo"
                action
                :multiple="false"
                :limit="1"
                :auto-upload="false"
                accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :on-change="importExcel"
                :file-list="uploadFiles"
              >
                <el-button size="small" type="text" style="padding: 0">点击上传</el-button>
              </el-upload>
            </div>
          </Divider>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item prop="taskName">
                <span slot="label">
                  工资包名称
                  <span style="font-size: 12px; color: #aabdd6">( 服务包拆分到发薪企业的工资包名称 )</span>
                </span>
                <el-input v-model="ruleForm.taskName" placeholder="请输入工资包名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="工资单模板" prop="taskTemp">
                <el-select
                  style="width: 100%"
                  v-model="ruleForm.taskTemp"
                  placeholder="请选择工资单模板"
                  @change="templateChange"
                >
                  <el-option
                    v-for="item in wagesList"
                    :key="item.code"
                    :label="item.title"
                    :value="item.code"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24">
            <el-col :span="12">
              <el-form-item label="工资包时间" prop="time">
                <el-date-picker
                  style="width: 100%"
                  v-model="ruleForm.time"
                  :default-value="timeDefaultShow"
                  :picker-options="pickerOptions"
                  :editable="false"
                  type="daterange"
                  @change="timeCustomer()"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <el-table :data="tableData" border style="width: 100%" v-if="!templateShow">
            <el-table-column v-for="(item, index) in headerTitle" :key="index" :label="item">
              <template slot-scope="scope">{{ scope.row[item] }}</template>
            </el-table-column>
            <el-table-column label="人员查询结果">
              <template slot-scope="scope">
                <p
                  style="margin: 0px; padding: 0px"
                  v-if="!scope.row.isExist"
                  class="font-color-danger"
                >用户信息有误</p>
                <p
                  style="margin: 0px; padding: 0px"
                  v-if="scope.row.isUnique"
                  class="font-color-danger"
                >用户重复</p>
                <p
                  style="margin: 0px; padding: 0px"
                  v-if="scope.row.isExist && !scope.row.isUnique"
                  class="font-color-success"
                >用户信息正确</p>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin: 20px 0; display: flex; justify-content: center">
            <el-button type="primary" :disabled="valid" @click="goSubmit('ruleForm')">分 包 预 览</el-button>
          </div>
        </el-form>
        <el-dialog :visible.sync="dialogVisible">
          <span slot="title">服务包分包明细预览</span>
          <el-tabs v-model="activeName" :stretch="true">
            <el-tab-pane
              v-for="(item, index) in enterpriseListTwo"
              :key="index"
              :label="item.ename"
              :name="item.ename"
            >
              <div v-if="item.isMain">
                工资包名称：{{ ruleForm.taskName }}
                <el-descriptions :column="3">
                  <el-descriptions-item label="归属项目">
                    {{
                    item.pname
                    }}
                  </el-descriptions-item>
                  <el-descriptions-item label="结算金额">￥：{{ item.totalSalary }}</el-descriptions-item>
                </el-descriptions>
                <el-table :data="item.data" style="width: 100%">
                  <el-table-column v-for="(item, index) in headerTitle" :key="index" :label="item">
                    <template slot-scope="scope">
                      {{
                      scope.row[item]
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column label="出勤工时">
                    <template slot-scope="scope">
                      <span>{{ scope.row.workDays.length * 4 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="出勤天数">
                    <template slot-scope="scope">
                      <span>{{ scope.row.workDays.length }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="状态">
                    <template slot-scope="scope">
                      <span v-if="scope.row.hasError" style="color:#ec808d">信息有误</span>
                      <span v-else style="color:#76ff64">通过</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
              <el-descriptions :column="3" v-if="!item.isMain">
                <el-descriptions-item label="归属项目">
                  {{
                  item.pname
                  }}
                </el-descriptions-item>
                <el-descriptions-item label="结算金额">￥：{{ item.totalSalary }}</el-descriptions-item>
              </el-descriptions>
              <el-tabs v-model="activeName2" v-if="!item.isMain" :stretch="true">
                <el-tab-pane :label="ruleForm.taskName + '-税'" name="first">
                  <el-table :data="item.data.filter((it) => it.isTax)" style="width: 100%">
                    <el-table-column
                      v-for="(item, index) in headerTitle"
                      :key="index"
                      :label="item"
                    >
                      <template slot-scope="scope">
                        {{
                        scope.row[item]
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column label="出勤工时">
                      <template slot-scope="scope">
                        <span>{{ scope.row.workDays.length * 4 }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="出勤天数">
                      <template slot-scope="scope">
                        <span>{{ scope.row.workDays.length }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="状态">
                      <template slot-scope="scope">
                        <span v-if="scope.row.hasError" style="color:#ec808d">信息有误</span>
                        <span v-else style="color:#76ff64">通过</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane :label="ruleForm.taskName" name="second">
                  <el-table :data="item.data.filter((it) => !it.isTax)" style="width: 100%">
                    <el-table-column
                      v-for="(item, index) in headerTitle"
                      :key="index"
                      :label="item"
                    >
                      <template slot-scope="scope">
                        {{
                        scope.row[item]
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column label="出勤工时">
                      <template slot-scope="scope">
                        <span>{{ scope.row.workDays.length * 4 }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="出勤天数">
                      <template slot-scope="scope">
                        <span>{{ scope.row.workDays.length }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="状态">
                      <template slot-scope="scope">
                        <span v-if="scope.row.hasError" style="color:#ec808d">信息有误</span>
                        <span v-else style="color:#76ff64">通过</span>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </el-tab-pane>
          </el-tabs>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" :disabled="!isHasError" @click="addService">提 交</el-button>
          </span>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
import XLSX from 'xlsx';
import * as _ from 'lodash';
import moment from 'moment';

export const customerAdd = api()('enterprise.add.json');
export const customerInfo = api()('enterprise.info.json');
export const customerEdit = api()('enterprise.config.edit.json');
export const taskTamplateList = api()('taskTamplate.list.json');
export const totalTaskCheckUser = api()('totalTask.checkUser.json');
export const upperEnterpriseDict = api()('upper_enterprise.dict.json');

export const enterpriseList = api()('enterprise.list.json');
export const projectList = api()('project.list.json');
export const totalTaskDispatchView = api()('totalTask.dispatch.view.json');
export const totalTaskDispatch = api()('totalTask.dispatch.json');
export const clearuserdispatch = api()('clearuser.dispatch.json');

export default {
  components: {
    Divider: () => import('../../components/divider.vue'),
  },
  data () {
    return {
      pageSize: 10,
      pageNumber: 1,
      total: 0,
      pickerMinDate: '', //获取开始选择时间
      pickerMaxDate: '', //获取结束选择时间
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          if (minDate) {
            this.pickerMinDate = minDate.getTime();
          }
          if (maxDate) {
            this.pickerMinDate = '';
            this.pickerMaxDate = maxDate.getTime();
          }
        },
        disabledDate: (time) => {
          const day32 = 14 * 24 * 3600 * 1000;
          if (this.pickerMinDate !== '') {
            let maxTime = this.pickerMinDate + day32;
            if (maxTime > new Date()) {
              maxTime = new Date();
            }
            return (
              time.getTime() > maxTime || time.getTime() > Date.now() - 86400000
            );
          }
          if (this.pickerMaxDate !== '' && !this.pickerMinDate) {
            let minTime = this.pickerMaxDate - day32;
            return (
              time.getTime() < minTime || time.getTime() > Date.now() - 86400000
            );
          }
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
      title: '',
      upperList: [],
      ruleForm: {
        name: '',
        time: [],
        taskName: '',
        taskTemp: '',
        remark: '',
        upperCode: ''
      },

      rules: {
        name: [
          { required: true, message: '请输入服务包名称', trigger: 'blur' },
        ],
        upperCode: [{ required: true, message: '请选择上游企业', trigger: 'blur' }],
        taskName: [
          {
            required: true,
            message: '请输入工资包名称',
            trigger: 'blur',
          },
        ],
        taskTemp: [
          {
            required: true,
            message: '请输入工资单模板',
            trigger: 'blur',
          },
        ],
        time: [
          {
            required: true,
            message: '请选择工资包时间',
            trigger: 'blur',
          },
        ],
        remark: [],
      },
      spoceList: [{ e: '', pcode: '' }],
      // baseTags: "创客姓名,手机号码,用户名,身份证号码,银行,银行卡号,服务周期,工作内容详情,应结算费用,平台服务费,实付金额,备注",
      enterpriseList: [],
      complianceObj: {
        ecode: '',
        ename: '',
        pname: '',
        pcode: '',
        isMain: true,
      },
      complianceList: [],
      complianceProject: [],
      wagesList: [],
      projectList: [],
      uploadFiles: [],
      fileName: '',
      templateDetail: {},
      submitTransData: [],
      headerTitle: [],
      tableData: [],
      templateShow: true,
      valid: true,
      dialogVisible: false,
      enterpriseListTwo: [],
      activeName: 0,
      tableDataTwo: [],
      pObj: [],
      timeDefaultShow: '',
      activeName2: 'first',
      isHasError: false
    };
  },
  async created () {
    this.code = this.$route.query.code;
    this.getTemplate();
    this.getEnterpriseList();
    this.getUpperList()
    if (this.code) {
      this.title = '编辑服务包';
      await this.logoData();
      this.isShow = true;
    } else {
      this.title = '添加服务包';
      this.isShow = false;
    }
  },
  mounted () {
    this.timeDefaultShow = new Date();
    this.timeDefaultShow.setMonth(new Date().getMonth() - 1);
  },
  methods: {
    async getUpperList () {
      const params = {};
      const res = await upperEnterpriseDict(params);
      this.upperList = res

    },
    async godel () {
      const params = {};
      const res = await clearuserdispatch(params);
      console.log(res);
    },
    async getTemplate () {
      const params = {};
      const res = await taskTamplateList(params);

      this.wagesList = res.list;
    },
    async getEnterpriseList () {
      const params = {};
      const res = await enterpriseList(params);
      this.complianceList = res.list
        .filter((it) => it.config.isNomal)
        .map((it) => ({ name: it.name, code: it.code, dispatch: false }));
      this.enterpriseList = res.list
        .filter((it) => !it.config.isNomal)
        .map((it) => ({ ename: it.name, ecode: it.code, dispatch: false }));
    },
    async getProjectList (code) {
      const params = {
        enterpriseCode: code,
      };
      const res = await projectList(params);
      this.projectList = res.list;
    },
    async templateChange () {
      this.valid = true
      this.tableData = []
      this.headerTitle = []
      this.templateDetail = this.wagesList.filter(
        (it) => it.code == this.ruleForm.taskTemp,
      )[0];

      this.headerTitle = this.templateDetail.selectTags.split(',');
      this.templateShow = false;
    },
    file2Xce (file) {
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;
          this.wb = XLSX.read(data, {
            type: 'binary',
          });
          const result = [];
          this.wb.SheetNames.forEach((sheetName) => {
            result.push({
              sheetName: sheetName,
              sheet: XLSX.utils.sheet_to_json(this.wb.Sheets[sheetName]),
            });
          });

          resolve(result);
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    async importExcel (param) {
      this.uploadFiles = [];
      this.fileName = param.userName;
      const arr = await this.getExcelHeaders(param);
      const arrNew = this.templateDetail.baseTags
        .split(',')
        .filter((it) => arr.includes(it));
      if (arrNew.length === this.templateDetail.baseTags.split(',').length) {
        this.file2Xce(param).then((item) => {
          if (item && item.length > 0) {
            if (item[0].sheet.length === 0) {
              return this.$message.error('传入表单无数据！');
            }

            if (item[0] && item[0].sheet && item[0].sheet.length) {
              // 校验传入表格字段

              const valid = item[0].sheet.every(
                (item) =>
                  item[this.templateDetail.name] &&
                  item[this.templateDetail.idCardNumber] &&
                  item[this.templateDetail.hourSalary] &&
                  item[this.templateDetail.totalSalary] &&
                  !isNaN(item[this.templateDetail.totalSalary]),
              );
              if (!valid) {
                return this.$message.error('请传入正确表格!');
              }
              this.tableData = item[0].sheet;
              // 把数据塞到表格预览
              this.templateShow = false;
              this.submitTransData = item[0].sheet.map((item) => {
                const t = {
                  name: item[this.templateDetail.name].replace(/\s/g, ''),
                  idCardNumber: item[this.templateDetail.idCardNumber].replace(
                    /\s/g,
                    '',
                  ),
                };
                return t;
              });
              // 查询数据情况
              this.userCompare();
            }
          }
        });
      } else {
        this.$message.error('请传入所选工资单模板相关的信息');
      }
    },
    goBack () {
      window.history.go(-1);
    },
    async userCompare () {
      const res = await totalTaskCheckUser({
        users: this.submitTransData,
      });
      this.tableData = this.tableData.map((it, i) => ({
        ...it,
        isExist: res.data[i].isExist,
        isUnique: res.data[i].isUnique,
        code: res.data[i].code,
      }));
      console.log(res);
      this.valid = !res.pass;
      console.log(this.valid);
    },
    async timeCustomer () {
      if (this.ruleForm.time != null) {
        this.ruleForm.time = [
          moment(this.ruleForm.time[0]).startOf('day').format('YYYY-MM-DD'),
          moment(this.ruleForm.time[1]).endOf('day').format('YYYY-MM-DD'),
        ];
      } else {
        this.pickerMinDate = '';
        this.pickerMaxDate = '';
      }
    },
    getExcelHeaders (file) {
      const getHeaderRow = (sheet) => {
        const headers = [];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        let C = range.s.c;
        for (C = range.s.c; C <= range.e.c; ++C) {
          const cell = sheet[XLSX.utils.encode_cell({ c: C, r: 0 })];
          if (cell && cell.t) {
            headers.push(XLSX.utils.format_cell(cell));
          }
        }
        return headers;
      };
      return new Promise(function (resolve) {
        const reader = new FileReader();
        reader.onload = function (e) {
          const data = e.target.result;

          this.wb = XLSX.read(data, {
            type: 'binary',
          });

          resolve(getHeaderRow(this.wb.Sheets.Sheet1 || this.wb.Sheets.sheet1));
        };
        reader.readAsBinaryString(file.raw);
      });
    },
    addE () {
      if (this.spoceList.length >= 3) {
        this.$message({
          message: '最多添加3个发薪企业',
          type: 'warning',
        });
      } else {
        this.projectList = [];
        if (this.spoceList.every((it) => it.e != '' && it.pcode != '')) {
          this.spoceList.push({ e: '', pcode: '' });
        } else {
          this.$message({
            message: '请完善发薪企业信息',
            type: 'warning',
          });
        }
      }
    },
    addfieldE (it) {
      this.getProjectList(it.e);
      const values = this.spoceList.map((n) => n.e);
      this.spoceList.map((i) => {
        if (i.e === it.e) {
          i.pcode = '';
          i.pname = '';
        }
        return i;
      });
      this.enterpriseList = this.enterpriseList.map((n) => {
        if (values.includes(n.ecode)) {
          n.dispatch = true;
        } else {
          n.dispatch = false;
        }
        return n;
      });
    },
    async addfieldEone (it) {
      this.complianceObj.pname = ''
      this.complianceObj.pcode = ''

      this.complianceProject = []
      this.complianceObj.ename = this.complianceList.filter(
        (n) => n.code === it,
      )[0].name;
      const params = {
        enterpriseCode: it,
      };
      const res = await projectList(params);
      this.complianceProject = res.list;
    },
    changePone (it) {
      this.complianceObj.pname = this.complianceProject.filter(
        (n) => n.code === it,
      )[0].name;
      console.log(this.complianceObj);
    },
    changeP (it) {
      const n = this.projectList.find((n) => n.code === it).name;

      const m = this.spoceList.map((i) => {
        if (i.pcode === it) {
          i.pname = n;
        }
        return i;
      });
      this.pObj = m;
    },
    focusPro (event) {
      this.getProjectList(event);
    },
    delScope (it, index) {
      console.log(it.e);
      if (this.spoceList.length > 1) {
        this.spoceList.splice(index, 1);
        this.spoceList = this.spoceList.filter((_it) => _it != it.e);
        this.enterpriseList = this.enterpriseList.map((n) => {
          console.log(n);
          if (it.e === n.ecode) {
            n.dispatch = false;
            console.log(n.dispatch)
          }
          return n;
        });
      } else {
        this.$message({
          message: '至少保留一个发新企业',
          type: 'warning',
        });
      }
    },
    async logoData () {
      const params = {
        code: this.code,
      };
      const res = await customerInfo(params);
      this.userInfo = res;
      this.authenticateState = this.userInfo.authenticateState;
      this.ruleForm.code = this.userInfo.config.character;
      (this.ruleForm.idCardNumber = this.userInfo.idCardNumber),
        (this.ruleForm.name = this.userInfo.name),
        (this.ruleForm.account = this.userInfo.super_user.username),
        // this.ruleForm.userPhone = this.userInfo.config.userPhone,
        (this.ruleForm.username = this.userInfo.config.username);
      this.ruleForm.smsPhone = this.userInfo.config.smsPhone;
      this.ruleForm.email = this.userInfo.config.email;
      // Object.keys(this.ruleForm).forEach(it => {
      //   this.ruleForm[it] = res[it]

      // })
    },
    close (formName) {
      this.$refs[formName].resetFields();
    },
    formatEn (data) {

      return data.map((item) => {
        const t = {
          username: item[this.templateDetail.name].replace(/\s/g, ''),
          code: item.code,

          hourSalary: item[this.templateDetail.hourSalary],
          idCardNumber: item[this.templateDetail.idCardNumber].replace(
            /\s/g,
            '',
          ),
          totalSalary: item[this.templateDetail.totalSalary]
            .toString()
            .includes('.')
            ? item[this.templateDetail.totalSalary].toString().split('.')[1]
              .length > 2
              ? Math.trunc(item[this.templateDetail.totalSalary] * 100) / 100
              : item[this.templateDetail.totalSalary]
            : item[this.templateDetail.totalSalary],
        };
        const unpick = _.without(
          this.templateDetail.selectTags.split(','),
          this.templateDetail.name,
          this.templateDetail.idCardNumber,
          this.templateDetail.totalSalary,
          this.templateDetail.hourSalary,
        );
        unpick.forEach((n) => (t[n] = item[n]));
        return t;
      });
    },
    goSubmit (formName) {

      let obj = this.enterpriseList
        .map((i) => ({ ecode: i.ecode, ename: i.ename }))
        .filter((it) => this.spoceList.map((n) => n.e).includes(it.ecode));

      obj = obj.map((n) => ({
        ...n,
        ..._.pick(
          this.pObj.find((m) => m.e === n.ecode),
          ['pname', 'pcode'],
        ),
      }));
      obj.push(this.complianceObj);
      console.log(obj)
      const arr = this.formatEn(this.tableData);
      this.$refs[formName].validate((valid) => {
        if (!obj.every((it) => it.ecode)) {
          this.$message.warning('发薪企业为空');
        } else if (!obj.every((it) => it.pcode)) {
          if (this.complianceObj.pcode === '') {

            this.$message.warning('单工伤公司的归属项目为空');
          }
          else {
            this.$message.warning('服务公司的归属项目为空');
          }

        }
        else if (!this.spoceList.every((it) => it.e)) {
          this.$message.warning('服务公司不能为空');
        }

        else {
          if (valid) {
            totalTaskDispatchView({
              startDate: this.ruleForm.time[0],
              endDate: this.ruleForm.time[1],
              users: arr,
              enterpriseList: obj,
            }).then((res) => {
              this.dialogVisible = true;
              this.isHasError = res.every(it => it.hasError === false)
              console.log(this.isHasError);
              this.enterpriseListTwo = res.map((ite) => ({
                ename: ite.data[0].ename,
                pname: ite.data[0].pname,
                totalSalary: ite.totalSalary,
                isMain: ite.data[0].isMain ? true : false,

                data: ite.data.map((item) => {
                  const t = {
                    [this.templateDetail.name]: item['username'],
                    [this.templateDetail.idCardNumber]: item['idCardNumber'],
                    [this.templateDetail.totalSalary]: item['salary'],
                    [this.templateDetail.hourSalary]: item['hourSalary'],
                  };
                  const unpick = _.without(
                    Object.keys(item),
                    this.templateDetail.name,
                    this.templateDetail.idCardNumber,
                    this.templateDetail.totalSalary,
                    this.templateDetail.hourSalary,
                  );
                  unpick.forEach((n) => (t[n] = item[n]));
                  return t;
                }),
              }));
              this.activeName = this.enterpriseListTwo[0].ename;

              console.log(this.enterpriseListTwo, 33333333333);
              // this.enterpriseListTwo = res.map((ite) => {
              //   ite.data = ite.data.map((item) => {
              //     const t = {
              //       [this.templateDetail.name]: item['username'],
              //       [this.templateDetail.idCardNumber]: item['idCardNumber'],
              //       [this.templateDetail.totalSalary]: item['salary'],
              //       [this.templateDetail.hourSalary]: item['hourSalary']
              //     }
              //     const unpick = _.without(Object.keys(item), this.templateDetail.name, this.templateDetail.idCardNumber, this.templateDetail.totalSalary, this.templateDetail.hourSalary)
              //     unpick.forEach(n => t[n] = item[n])
              //     return t
              //   })
              //   return ite
              // }
              // );
            });
          } else {
            return false;
          }
        }
      });
    },
    addService () {
      let obj = this.enterpriseList
        .map((i) => ({ ecode: i.ecode, ename: i.ename }))
        .filter((it) => this.spoceList.map((n) => n.e).includes(it.ecode));

      obj = obj.map((n) => ({
        ...n,
        ..._.pick(
          this.pObj.find((m) => m.e === n.ecode),
          ['pname', 'pcode'],
        ),
      }));
      obj.push(this.complianceObj);
      const arr = this.formatEn(this.tableData);
      totalTaskDispatch({
        name: this.ruleForm.name,
        remark: this.ruleForm.remark,
        taskName: this.ruleForm.taskName,
        taskTemp: this.ruleForm.taskTemp,
        startDate: this.ruleForm.time[0],
        endDate: this.ruleForm.time[1],
        upperCode: this.ruleForm.upperCode,
        users: arr,
        enterpriseList: obj,
      }).then((res) => {
        console.log(res);
        this.$message.success('提交成功');
        window.history.go(-1);
        this.dialogVisible = false;
      });
    },
  },
};
</script>
<style>
.el-card__body {
  text-align: left;
}
</style>
<style lang="scss" scope>
.scope_hide {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  .hide_one {
    display: flex;
    align-items: center;
    span {
      width: 60px;
      text-align: center;
    }
  }
}
</style>
